import React from "react"
// import BasicLayout from "../components/layout.css"
// import PropTypes from 'prop-types'
// import { StaticQuery, graphql } from 'gatsby'
// import $ from 'jquery/dist/jquery.slim'
// import 'popper.js'

import Header from "../components/header/header"
import Footer from "../components/footer/footer"
// import Navbar from "../components/Navbar"

const Layout = (props) => {
  return (
      <div>
          <Header />
          {props.children}
          <Footer />
      </div>
  )
}

export default Layout
