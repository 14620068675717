import React from "react"
// import { Link, graphql } from "gatsby"
// import Img from "gatsby-image"
import footerStyles from "../footer/footer.module.scss"
// import CITechImg from "src\images\CITechlogotransparentpurple.png"
// import CITechImg from "./images/CITechlogotransparentpurple.png"
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faAngleRight,faFacebookF,faTwitter,faInstagram} from '@fortawesome/free-solid-svg-icons'



const Footer = ((props) => {
  return (
    <div className={footerStyles.maindiv}>
        {/* footer section */}
        <div className={footerStyles.footerdiv}>
            <div className={footerStyles.divone}>
                <div className={footerStyles.lowdiv}>
                    <div style={{background:'black',color:'orange',fontWeight:'bold'}}>LADY ON</div>
                    {/* <div style={{background:'black',color:'orange',fontWeight:'bold'}}>ON</div> */}
                    <div style={{background:'black',color:'orange',fontWeight:'bold'}}>WHEELS</div>
                </div>

                <div className={footerStyles.citechtextdiv}>
                    <div style={{background:'black',color:'white'}}>website designed by:</div>
                </div>

                <div className={footerStyles.citechdiv}>
                  <div className={footerStyles.citechdivimg}><a href="https://www.cyberinntech.com">CYBERINNTECH.</a></div>
                </div>

                <div className={footerStyles.socialdiv}>
                    <div className={footerStyles.socialhead}>SOCIAL</div>
                    <a className={footerStyles.socialTwitter} href="https://twitter.com/ladyonwheels_za">{/* <span className={footerStyles.footerspan}><FontAwesomeIcon icon={faTwitter}/></span> */}TWITTER</a>
                    <a className={footerStyles.socialFacebook} href="https://web.facebook.com/ladyon.wheels.5?_rdc=1&_rdr">{/* <span className={footerStyles.footerspan}><FontAwesomeIcon icon={faFacebookF}/></span> */}FACEBOOK</a>
                    <a className={footerStyles.socialInstagram} href="https://www.instagram.com/lady_onwheelsza/?igshid=27x1129mcbae">{/* <span className={footerStyles.footerspan}><FontAwesomeIcon icon={faInstagram}/></span> */}INSTAGRAM</a>
                </div>

                <div className={footerStyles.menudiv}>
                    <div className={footerStyles.menudivhead}>MENU</div>
                    <a className={footerStyles.menudiv1} href="/services">{/* <span className={footerStyles.footerspan}><FontAwesomeIcon icon={faAngleRight}/></span> */}SERVICES</a>
                    <a className={footerStyles.menudiv2} href="/getaquote">{/* <span className={footerStyles.footerspan}><FontAwesomeIcon icon={faAngleRight}/></span> */}GET A QUOTE</a>
                    <a className={footerStyles.menudiv3} href="/contact">{/* <span className={footerStyles.footerspan}><FontAwesomeIcon icon={faAngleRight}/></span> */}CONTACT</a>
                </div>

                <div className={footerStyles.enquirediv}>
                    <div className={footerStyles.enquirehead}>ENQUIRE</div>
                    <div className={footerStyles.enquiretext}>For submissions, enquiries and collaboration opportunities: bookings@ladyonwheelsservices.co.za</div>
                    <div className={footerStyles.enquireemail}>-</div>
                    <div className={footerStyles.tothetop}>{/* BACK TO THE TOP */}</div>
                </div>

                {/* <div className={footerStyles.topbutdiv}>
                    <div style={{background:'black',color:'white'}}>^</div>
                    <div style={{background:'black',color:'white'}}>BACK TO THE TOP</div>
                </div> */}
            </div>
        </div>
    </div> 
  )
}
)

export default Footer

// export const pageQuery = graphql`
//   query {
//     citech: file(relativePath: {eq: "CITechlogotransparentpurple.png"}) {
//       childImageSharp {
//         fluid(maxWidth: 1000) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `