import React from 'react';
import styled from 'styled-components';
import MobileNav from './mobileNav';

const Nav = styled.nav`
  width: 100%;
  height: 55px;
  border-bottom: solid 3px rgba(227,91,0,0.8);
  box-shadow: 0px 8px 9px rgba(0,0,0,0.5);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 20;

  .logo {
    padding: 10px 0;
    color: rgba(227,91,0,0.8);
    font-weight: bold;
    font-size: 20px;
    /* -webkit-text-stroke: 0.6px black; */
  }
`

const Navbar = () => {
  return (
    <Nav>
      <div className="logo">
        Lady On Wheels
      </div>
      <MobileNav />
    </Nav>
  )
}

export default Navbar