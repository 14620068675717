import React from 'react';
import styled from 'styled-components';
import {Link} from "gatsby"

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  li {
    font-size: 14.5px;
    padding: 15px 8px;
    font-weight: bold;
    justify-content: center;
    text-align: center;
    color: black;
  }

  li:hover {
    color: darkorange;
  }

  span {
    padding-left: 15px;
    color: rgba(227,91,0,0.8);
    font-weight: bold;
  }

  @media (max-width: 1050px) {
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 70vw;
    padding-top: 3.5rem;
    border-left: solid 2px black;
    /* box-shadow: -8px 0px 0px rgba(0,0,0,0.3); */
    transition: transform 0.3s ease-in-out;

    span {
      display: none;
    }

    li {
      color: #000;
      padding: 15px 0;
      background-color: #fff;
      text-align: center;
      font-weight: bold;
      text-decoration: none;
      width: 80%;
      margin-left: 10%;

      &:nth-child(2) {
        border-top: solid 3px rgba(227,91,0,0.8);
      }

      &:nth-child(3) {
        border-top: solid 3px rgba(227,91,0,0.8);
        border-bottom: solid 3px rgba(227,91,0,0.8);
      }

      &:nth-child(4) {
        border-bottom: solid 3px rgba(227,91,0,0.8);
      }

      &:nth-child(5) {
        border-bottom: solid 3px rgba(227,91,0,0.8);
      }

      &:nth-child(6) {
        border-bottom: solid 3px rgba(227,91,0,0.8);
      }

      &:nth-child(7) {
        border-bottom: solid 3px rgba(227,91,0,0.8);
      }
    }
  }
`;

const DesktopNav = ({ open }) => {
  return (
    <Ul open={open}>
      <li></li>
      <li><Link style={{color:'#000',textDecoration:'none'}} to="https://www.ladyonwheels.co.za">HOME<span>|</span></Link></li>
      <li><Link style={{color:'#000',textDecoration:'none'}} to="/services">SERVICES<span>|</span></Link></li>
      <li><Link style={{color:'#000',textDecoration:'none'}} to="/getaquote">QUOTE?<span>|</span></Link></li>
      <li><Link style={{color:'#000',textDecoration:'none'}} to="/contact">CONTACT<span>|</span></Link></li>
      <li><Link style={{color:'#000',textDecoration:'none'}} to="/about">ABOUT<span>|</span></Link></li>
      <li><Link style={{color:'#000',textDecoration:'none'}} to="https://sacoronavirus.co.za/">COVID 19</Link></li>
    </Ul>
  )
}

export default DesktopNav