import React from "react"
// import headerStyles from "../"
// C:\Users\Smanga\ladyonwheelswebsite\src\styles\header.module.scss
// src\styles\header.module.scss
import headerStyles from "../header/header.module.scss"
import Navbar from "../header/Nav/navbar"

const HeaderPage = () => {
  return (
    <div className={headerStyles.maindiv}>
      <Navbar/>
    </div>
  )
}

export default HeaderPage